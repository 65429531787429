import React, { useState, useEffect, useRef } from "react"
import "./ProgramsCarousel.scss"
import PropTypes from "prop-types"
import Slider from "react-slick"
import EqualizerAnimation from "../Animations/Equalizer"
import { useTranslation } from "react-i18next"
import { useGuestApi } from "../../hooks/useApi"
import { getProgramCover, getSingleImage } from "../../helpers/Publications"
import {
  nowPlaying,
  formatProgramTime,
  getRandomColor,
} from "../../helpers/Programs"
import { imageUrl } from "../../helpers/ImageUrl"
import Icon from "../Icon/Icon"

function ProgramsCarousel(props) {
  const { data } = props
  const { getProgramGuide } = useGuestApi()
  const [guide, setGuide] = useState([])
  const [currentSlide, setCurrentSlide] = useState(0)
  const slider = useRef()
  useEffect(() => {
    getProgramGuide().then(response => {
      response.map((item, index) => {
        if (nowPlaying(item)) {
          setCurrentSlide(index)
        }
      })
      setGuide(response)
    })
  }, [])
  const settings = {
    initialSlide: currentSlide,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  const { t } = useTranslation()
  return (
    <div className="programs--carousel">
      {guide.length > 0 && (
        <>
          <div className="head">
            <h3>{t("programsCarousel.title")}</h3>
          </div>
          {guide.length && (
            <Slider {...settings} ref={slider}>
              {guide.map((item, index) => {

                if(!item?.show) {
                  return null;
                }

                return (
                <div key={index}>
                  <div
                    className={`program`}
                    style={{ backgroundColor: getRandomColor(index) }}
                  >
                    <div className="program--visual">
                      <img
                        src={imageUrl(getProgramCover(item?.show?.cover), 70, 70)}
                        alt={item?.show?.name}
                      />
                    </div>
                    <div className="program--info">
                      <h3 className="program--name">{item?.show?.name}</h3>
                      {item?.show?.content && (
                        <h4 className="program--person">{item?.show?.content}</h4>
                      )}
                    </div>
                    {nowPlaying(item) && (
                      <div
                        className="indicator"
                        style={{ borderTopColor: getRandomColor(index) }}
                      />
                    )}
                  </div>
                  <div className="program--times">
                    {nowPlaying(item) ? (
                      <EqualizerAnimation className="fill-primary" />
                    ) : (
                      <Icon className="program--times-icon" name="clock" />
                    )}
                    <p>{formatProgramTime(item)}</p>
                  </div>
                </div>
                )
              })}
            </Slider>
          )}
        </>
      )}
    </div>
  )
}

ProgramsCarousel.propTypes = {
  data: PropTypes.array,
}

export default ProgramsCarousel
