import React from "react"
import Lottie from "react-lottie"
import animation from "./data/equalizer"
import "./Styles.scss"
// TODO: terug fixen

function EqualizerAnimation({ className }) {
  return (
    <div className={`equalizer ${className}`}>
      <Lottie
        options={{
          animationData: animation,
        }}
      />
    </div>
  )
}

EqualizerAnimation.defaultProps = {
  className: "fill-white",
}

export default EqualizerAnimation
